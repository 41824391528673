import React from 'react';
import './tradeFiPoolDetail.scss';
import { BasicTable } from '../../../components/tables';
import { useGetLendingPoolAgreementsQuery } from '../../../services/tradeFiService';
import Loading from '../../../components/LoadingSkeleton/Loading';
import RiskRatingBadge from '../../../components/RiskRatingBadge'; // Import the new component
import { formatDuration } from '../../../utils/projectUtils'; // Adjusted import path
import { formatCurrency } from '../../../utils/formatUtils';
const ReceivablesTransparency = ({ data, poolId }) => {
  const {
    data: receivableTransparency,
    error,
    isLoading,
  } = useGetLendingPoolAgreementsQuery(poolId);
  if (isLoading) return <Loading />;
  if (error) return <div>Error loading data</div>;

  let tableData = Array.isArray(receivableTransparency.data)
    ? receivableTransparency.data
    : [];
  tableData = tableData.map((item) => {
    const start_date = item.start_date ? new Date(item.start_date) : new Date();
    const end_date = item.end_date ? new Date(item.end_date) : new Date();
    const contract_term_in_months =
      (end_date.getFullYear() - start_date.getFullYear()) * 12 +
      (end_date.getMonth() - start_date.getMonth());
    return {
      ...item,
      contract_term: `${contract_term_in_months} months`,
    };
  });

  return (
    <div className="row">
      <div className="col-12">
        <BasicTable
          data={tableData}
          columns={[
            {
              header: 'Receivable ID',
              accessor: 'receivable_id',
              Cell: ({ value }) => value || 0,
            },
            {
              header: 'Contract Term',
              accessor: 'contract_term',
              Cell: ({ value }) => value || 0,
            },
            {
              header: 'Expected Return',
              accessor: 'expected_return',
              Cell: ({ value }) => `${value || 0}%`,
            },
            {
              header: 'Risk Rating',
              accessor: 'risk_rating',
              Cell: ({ value }) => <RiskRatingBadge rating={value || 0} />,
            },
            {
              header: 'Cycle Length',
              accessor: 'cycle_length_in_days',
              Cell: ({ value }) => `${value || 0} days`,
            },
            {
              header: 'Cycles (Completed/Total)',
              accessor: 'cycles_completed',
              Cell: ({ row }) =>
                `${row.original.cycles_completed || 0}/${row.original.total_cycles || 0}`,
            },
            {
              header: 'Total Interest Earned (USD)',
              accessor: 'total_interest_earned',
              Cell: ({ value }) => formatCurrency(value || 0),
            },
          ]}
          isSortable={false}
          isPaginated={tableData.length > 5}
          pageSize={5}
          currentPage={0}
          showCheckbox={false}
        />
      </div>
    </div>
  );
};

export default ReceivablesTransparency;
