import React from 'react';
import { BasicTable } from '../../../components/tables';
import ModalLayout from '../../../components/modals/ModalLayout';
import Typography from '../../../components/Typography/Typography';
import './tradeFiPoolDetail.scss';
import { useModal } from '../../../hooks/modalsHooks';

const RecentActivity = ({ recentActivity }) => {
  const mappedLoansData = recentActivity.map((activity) => ({
    contract: activity.contract_address,
    type: activity.type,
    date: new Date(activity.date).toISOString().split('T')[0],
    transaction: activity.explorer_url,
  }));
  const { hideModal } = useModal();

  const handleClose = () => {
    hideModal();
  };

  return (
    <ModalLayout width="100%" show={true} onHide={handleClose}>
      <div className="card" style={{ borderRadius: '10px' }}>
        <ModalLayout.Header>
          <div className="w-100 p-4 d-flex justify-content-center">
            <Typography variant="h3" className="text-center">
              Recent Activity
            </Typography>
          </div>
        </ModalLayout.Header>
        <ModalLayout.Body>
          <div className="text-center pt-2 px-3">
            <BasicTable
              data={mappedLoansData}
              columns={[
                {
                  header: 'Contract',
                  accessor: 'contract',
                  Cell: ({ value }) => (
                    <div className="truncate" title={value}>
                      {value}
                    </div>
                  ),
                },
                {
                  header: 'Type',
                  accessor: 'type',
                },
                {
                  header: 'Date',
                  accessor: 'date',
                },
                {
                  header: 'Transaction Link',
                  accessor: 'transaction',
                  Cell: ({ value }) => (
                    <a
                      href={value}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="link-button"
                    >
                      View
                    </a>
                  ),
                },
              ]}
              isSortable={false}
              isPaginated={mappedLoansData?.length > 5}
              pageSize={5}
              currentPage={0}
              showCheckbox={false}
            />
          </div>
        </ModalLayout.Body>
        <ModalLayout.Footer>
          <div className="m-4 d-flex justify-content-center flex-row align-items-center">
            <button
              type="button"
              className="d-flex align-items-center btn btn-secondary mx-2"
              onClick={handleClose}
            >
              Close
            </button>
          </div>
        </ModalLayout.Footer>
      </div>
    </ModalLayout>
  );
};

export default RecentActivity;
