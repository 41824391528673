import React from 'react';
import './tradeFiPoolDetail.scss';
import Typography from '../../../components/Typography/Typography';

const getInitials = (name) => {
  if (!name) return '';
  const nameParts = name.split(' ');
  console.log(nameParts);
  if (nameParts.length > 1) {
    return (nameParts[0][0] + nameParts[1][0]).toUpperCase();
  }
  return (name[0] + name[1]).toUpperCase();
};

const BrandsInThePool = ({ data }) => {
  console.log(data);
  return (
    <div className="card mt-2 p-3 h-100">
      <Typography
        variant="h2"
        className="m-0 pt-3 ps-md-3 ps-0 text-center text-md-start"
      >
        In this Pool
      </Typography>
      <div className="d-flex flex-wrap justify-content-center mt-4">
        <div className="row w-100">
          {data.length === 0 ? (
            <p className="text-center w-100">No brands available</p>
          ) : (
            data.map((brand) => (
              <div className="brand-item text-center col-12 col-md-4" key={brand.id}>
                {brand.brand_logo?.file ? (
                  <img
                    src={brand.brand_logo.file}
                    onError={(e) => {
                      e.target.src = 'https://dummyimage.com/300x300/e8eff0/000000.png&text=No+image+';
                    }}
                    alt={brand.name}
                    className="brand-image"
                  />
                ) : (
                  <div className="brand-initials">
                    {getInitials(brand.name)}
                  </div>
                )}
                <p className="pt-2">{brand.name}</p>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default BrandsInThePool;