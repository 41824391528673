import React from 'react';
import Typography from '../../../components/Typography/Typography';
import Button from '../../../components/Button/Button';
import './tradeFiPoolDetail.scss';
import { useModal } from '../../../hooks/modalsHooks';
import RecentActivity from './RecentActivity';

const AccountabilityFeatures = ({ recentActivity ,poolInfo,}) => {
  const { showModal } = useModal();

  const handleViewAuditReport = () => {
    showModal({ modalType: 'RecentActivity', modalProps: { recentActivity } });
  };
  const mileStones = poolInfo.meta_data?.milestones || [];

  return (
    <div className="row">
      <div className="col-md-6 mb-3">
        <div className="card h-100">
          <div className="card-body text-center text-md-start">
            <Typography variant="h4" className="mb-3">
              Audit Summary
            </Typography>
            <Typography variant="body1" className="mb-3">
              Access the latest third-party audit findings for the {poolInfo.pool_name} pool.
            </Typography>
            <Button variant="primary" onClick={handleViewAuditReport}>
              View Audit Report
            </Button>
          </div>
        </div>
      </div>
      <div className="col-md-6 mb-3">
        <div className="card h-100">
          <div className="card-body text-center text-md-start">
            <Typography variant="h4" className="mb-3">
              Recent Milestones
            </Typography>
            {mileStones.length > 0 ? (
              <ul className="list-unstyled">
                {mileStones.map((milestone, index) => (
                  <li key={index} className="mb-2">
                    <i className="bi bi-check-circle-fill text-success me-2"></i>
                    {milestone}
                  </li>
                ))}
              </ul>
            ) : (
              <Typography variant="body1" className="mb-3">
                There are no recent milestones.
              </Typography>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountabilityFeatures;
