import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth, publicBaseQuery } from './baseQueries';

export const tradeFiAPI = createApi({
    reducerPath: 'tradeFiAPI',
    baseQuery: publicBaseQuery,
    tagTypes: ['TradeFinance'],
    endpoints: (builder) => ({
        getAllLendingPool: builder.query({
            query: () => '/api/templates/tradefi/lending_pool_group/',
            providesTags: ['TradeFinance'],
        }),
        getGraphData: builder.query({
            query: ({ id, year }) => {
                let baseUrl = `/api/templates/tradefi/lending_pool_group/${id}/repayments/`;
                if (year) {
                    baseUrl += `?year=${year}`;
                }
                return baseUrl;
            },
            providesTags: ['TradeFinance'],
        }),
        getLendingPoolById: builder.query({
            query: (poolid) => `/api/templates/tradefi/lending_pool_group/${poolid}/`,
            providesTags: ['TradeFinance'],
        }),
        getLoansInLendingPool: builder.query({
            query: (id) => `/api/templates/tradefi/lending_pool_group/${id}/loans/`,
            providesTags: ['TradeFinance'],
        }),
        getAllLoanInfo: builder.query({
            query: () => '/api/templates/tradefi/lending_pool/all-loan-info/',
            providesTags: ['TradeFinance'],
        }),
        getOverviewOfAPool: builder.query({
            query: (id) => `/api/templates/tradefi/lending_pool_group/${id}/overview/`,
            providesTags: ['TradeFinance'],
        }),
        getAllBorrowersInfo: builder.query({
            query: (id) => `/api/templates/tradefi/lending_pool_group/${id}/borrowers/`,
            providesTags: ['TradeFinance'],
        }),
        getAllRecentActivity: builder.query({
            query: (id) => `/api/templates/tradefi/lending_pool_group/${id}/tx-history/`,
            providesTags: ['TradeFinance'],
        }),
        getLendingPoolAgreements: builder.query({
            query: (id) => `/api/templates/tradefi/lending_pool_group/${id}/lending-agreements/`,
            providesTags: ['TradeFinance'],
        }),
    }),
});

export const { 
    useGetAllLendingPoolQuery, 
    useGetGraphDataQuery,
    useGetLendingPoolByIdQuery, 
    useGetLoansInLendingPoolQuery, 
    useGetAllLoanInfoQuery, 
    useGetOverviewOfAPoolQuery, 
    useGetAllBorrowersInfoQuery, 
    useGetAllRecentActivityQuery,
    useGetLendingPoolAgreementsQuery,
} = tradeFiAPI;