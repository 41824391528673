import React from "react";
import { BasicTable } from "../../../components/tables";
import { formatRisk } from "../../../utils/projectUtils";
import { formatCurrency } from "../../../utils/formatUtils";
const BorrowersTab = ({ borrowersData }) => {
  const tableData = borrowersData.map((item) => ({
    name: item.borrower_name,
    amount: formatCurrency(item?.amount || 0),
    interestRate: `${item.portfolio_share}%`,
    riskRating: item.risk_rating,
    yearsActive: item.years_active || "N/A", // default to 'N/A' if years_active is null
  }));
  return (
    <div>
      <BasicTable
        data={tableData}
        columns={[
          {
            header: "Borrower Name",
            accessor: "name",
          },
          {
            header: "Portfolio share",
            accessor: "interestRate",
          },
          {
            header: "Amount (USD)",
            accessor: "amount",
          },
          {
            header: "Risk Rating",
            accessor: "riskRating",
            Cell: ({ value }) => {
              return (
                <div className="risk-rating-component d-md-flex align-items-md-center">
                  <div className="progress risk-rating-progress">
                    <div
                      className="progress-bar progress-bar-responsive"
                      role="progressbar"
                      style={{
                        width: `${value}%`,
                      }}
                      aria-valuenow={value}
                      aria-valuemin="0"
                      aria-valuemax="100"
                    />
                  </div>
                  <span className="risk-value">{formatRisk(value)}</span>
                </div>
              );
            },
          },
          {
            header: "Years active",
            accessor: "yearsActive",
          },
        ]}
        isSortable={false}
        isPaginated={borrowersData?.length > 5}
        pageSize={5}
        currentPage={0}
        showCheckbox={false}
      />
    </div>
  );
};

export default BorrowersTab;
