import React from 'react';
import TradeFiOverviewCard from '../../../components/tradeFinance/Card/tradeFiOverviewCard';
import { formatCurrency } from '../../../utils/formatUtils';
const PoolSummary = ({ data,lendingDetailData }) => {
return (
	<>
		<div className="row">
			<div className="col-md-3 mb-3">
				<TradeFiOverviewCard
					title="Active Loans"
					mainValue={formatCurrency(data?.total_approved_loan_amount || 0, 'USD')}
					icon={<i class="bi bi-broadcast"></i>}
					/>
			</div>
			<div className="col-md-3 mb-3">
				<TradeFiOverviewCard
					title="Average Interest Rate"
					mainValue={data?.average_interest_rate
						? `${new Intl.NumberFormat().format(
							data?.average_interest_rate
							)}%`
						: '0.00 %'}
					icon={<i className="bi bi-percent"></i>}
					/>
			</div>
			<div className="col-md-3 mb-3">
				<TradeFiOverviewCard
					title="Total Loans Repaid"
					mainValue={formatCurrency(data?.total_loans_repaid_amount || 0, 'USD')}
					icon={<i className="bi bi-check-circle"></i>}
				/>
			</div>
			<div className="col-md-3 mb-3">
				<TradeFiOverviewCard
					title="Interest Repaid"
					mainValue={formatCurrency(data?.total_interest_repaid || 0, 'USD')}
					icon={<i className="bi bi-graph-up"></i>}
				/>
			</div>
		</div>
	</>
);
};

export default PoolSummary;
