export const ProjectTemplates = {
    "SUPPLY_CHAIN": "SUPPLY_CHAIN",
    "TOKENIZATION": "BAO"
}

export function getProjectTemplateFromAPI(apiTemaplte){
    switch(apiTemaplte){
        case "SUPPLY_CHAIN":
            return ProjectTemplates.SUPPLY_CHAIN;
        
        case "BAO":
            return ProjectTemplates.TOKENIZATION;
        
        default:
            return null;
    }
}

export function getProjectTemplateName(template){
    switch(template){
        case "SUPPLY_CHAIN":
            return "Supply chain traceability";
        
        case "BAO":
            return "Tokenization";
        
        default:
            return "Other";
    }
}

export function string_to_snake_case(str) { 
    return str && str.match( 
/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g) 
        .map(s => s.toLowerCase()) 
        .join('_'); 
}


export function formatRisk(rating){
    if(rating <= 10){
        return "Very Low Risk"
    }

    if(rating <= 30){
        return "Low Risk"
    }

}

export function convertRiskToText(rating){
    if(rating <= 30){
        return "low"
    }

    if(rating <= 66){
        return "medium"
    }

    if(rating <= 100){
        return "high"
    }
}

/**
 * Formats a duration string into a human-readable format.
 *
 * The input duration string should be in the format of "XdYmZy" where:
 * - X is the number of days followed by 'd'
 * - Y is the number of months followed by 'm'
 * - Z is the number of years followed by 'y'
 *
 * @param {string} duration - The duration string to format.
 * @returns {string} - The formatted duration string in a human-readable format.
 *
 * @example
 * returns '2 years 3 months 5 days'
 * formatDuration('2y3m5d');
 *
 * @example
 * returns '1 month 1 day'
 * formatDuration('1m1d');
 */
export function formatDuration(duration){
    const regex = /(\d+y)?(\d+m)?(\d+d)?/;
    const matches = duration.match(regex);
  
    let formattedDuration = '';
  
    if (matches[1]) {
      const years = matches[1].replace('y', '');
      formattedDuration += `${years} year${years > 1 ? 's' : ''} `;
    }
    if (matches[2]) {
      const months = matches[2].replace('m', '');
      formattedDuration += `${months} month${months > 1 ? 's' : ''} `;
    }
    if (matches[3]) {
      const days = matches[3].replace('d', '');
      formattedDuration += `${days} day${days > 1 ? 's' : ''} `;
    }
  
    return formattedDuration.trim();
};