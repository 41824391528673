import './tradeFiOverview.scss';
import Typography from '../../../components/Typography/Typography';
import {
  useGetAllLendingPoolQuery,
  useGetAllLoanInfoQuery,
} from '../../../services/tradeFiService';
import { logEvent } from '../../../utils/amplitudeUtlis';
import { useNavigate } from 'react-router-dom';
import TradeFiSummary from './TradeFiSummary';
import PoolList from './PoolList';
import Loading from '../../../components/LoadingSkeleton/Loading';
import { formatCurrency } from '../../../utils/formatUtils';
export default function TradeFiOverviewPage() {
  const navigate = useNavigate();

  const { data: lendingPool, isLoading: isLendingLoading } =
    useGetAllLendingPoolQuery();
  const lendingData = lendingPool?.data;
  const { data: loanStatisticsData, isLoading: isLoanStatisticsLoading } =
    useGetAllLoanInfoQuery();

  const handleCreateNewPool = async () => {
    logEvent({
      eventName: 'pool_creation_initiated',
      eventProperties: {},
    });
  };

  const handleOnViewPoolLinkClick = (id) => {
    logEvent({
      eventName: 'pool_view_button_clicked',
      eventProperties: {
        pool_name: lendingData?.name,
      },
    });
    navigate(`/overview/${id}`);
  };

  const tableData = lendingData?.map((pool) => ({
    id: pool.id,
    name: pool.pool_name,
    description:pool.description,
    apy: pool.apy.toFixed(2) + '%',
    poolSize: formatCurrency(pool.pool_size || 0, 'USD'),
    riskRating: pool.risk_rating || 'N/A',
    totalInvestment: formatCurrency(pool.total_investment || 0, 'USD'),
    cycle: pool.total_cycles_disbursed,
    totalActiveLoans: formatCurrency(pool.total_active_loans || 0, 'USD'),
    totalLoanDisbursed: formatCurrency(pool.total_loan_amount_disbursed || 0, 'USD'),
  }));

  return isLendingLoading || isLoanStatisticsLoading ? (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ height: '100vh' }}
    >
      <Loading />
    </div>
  ) : (
    <div className="container-fluid pt-2">
      <div className="row d-flex justify-content-center flex-wrap flex-md-nowrap align-items-center pt-3 justify-content-md-start">
        <div className="col text-center text-md-start">
          <Typography variant="h1">RWA Platform Dashboard</Typography>
        </div>
      </div>
      <TradeFiSummary
        overviewData={loanStatisticsData?.data}
        lendingDetailData={tableData}
      />
      <PoolList
        tableData={tableData}
        handleOnViewPoolLinkClick={handleOnViewPoolLinkClick}
        handleCreateNewPool={handleCreateNewPool}
      />
    </div>
  );
}
