import { useMemo, useEffect, useState } from "react";
import Typography from "../../../components/Typography/Typography";
import BasicInfo from "./BasicInfo";
import "./tradeFiPoolDetail.scss";

import BorrowersTab from "./BorrowersTab";
import LoanTab from "./LoanTab";
import Tab from "../../../components/tradeFinance/Tab/Tab";
import PoolSummary from "./PoolSummary";
import {
	useGetAllBorrowersInfoQuery,
	useGetAllRecentActivityQuery,
	useGetGraphDataQuery,
	useGetLendingPoolByIdQuery,
	useGetLoansInLendingPoolQuery,
	useGetOverviewOfAPoolQuery,
} from "../../../services/tradeFiService";
import { useParams } from "react-router-dom";
import Loading from "../../../components/LoadingSkeleton/Loading";
import BarChartComponent from "../../../components/Chart/BarChart";
import BrandsInThePool from "./BrandsInThePool";
import AccountabilityFeatures from "./AccountabilityFeatures";
import ReceivablesTransparency from "./ReceivablesTransparency";

const CardSection = ({ title, children }) => (
	<div className="row py-3">
		<div className="col-12">
			<div className="card mt-2 p-3 h-100">
				<div className="card-title text-center text-md-start">
					<Typography variant="h2" className="m-0 pt-3 ps-md-3 ps-0">
						{title}
					</Typography>
				</div>
				<div className="card-body">{children}</div>
			</div>
		</div>
	</div>
);

const TradeFiPoolDetailPage = () => {
	const [isPageLoading, setIsPageLoading] = useState(true);
	const { poolId } = useParams();
	const { data: basicInfo, isLoading: isBasicInfoLoading } =
		useGetLendingPoolByIdQuery(poolId);

	const { data: poolOverview, isLoading: isPoolOverviewLoading } =
		useGetOverviewOfAPoolQuery(poolId);
	const { data: graphData, isLoading: isGraphDataLoading } =
		useGetGraphDataQuery({ id: poolId, year: 2024 });

	const { data: loansData, isLoading: isLoanLoading } =
		useGetLoansInLendingPoolQuery(poolId);

	const { data: borrowersData, isLoading: isBorrowersLoading } =
		useGetAllBorrowersInfoQuery(poolId);

	const { data: recentActivityData, isLoading: isRecentActivityLoading } =
		useGetAllRecentActivityQuery(poolId);

	useEffect(() => {
		setIsPageLoading(true);
			
		const timer = setTimeout(() => {
			setIsPageLoading(false);
		}, 1000);
	
		return () => {
			clearTimeout(timer);
			setIsPageLoading(true);	
		};
	}, [poolId]); 

	const tabsData = [
		{
			path: "loan",
			name: "Loan Pools",
			component: <LoanTab loansData={loansData?.data} />,
		},
		{
			path: "borrowers",
			name: "Borrowers",
			component: <BorrowersTab borrowersData={borrowersData?.data} />,
		},
	];
	// Map the response data to the format your component expects
	const graphDataMapping = graphData?.data?.graph_data?.map((item) => ({
		month: item.month_name.substring(0, 3), // Get the first 3 letters of the month
		actualRepaymentValue: item.actual_payment,
		projectedRepaymentValue: item.projected_payment,
	}));
	if (
		isPageLoading ||
		isBasicInfoLoading ||
		isPoolOverviewLoading ||
		isGraphDataLoading ||
		isLoanLoading ||
		isBorrowersLoading ||
		isRecentActivityLoading
	) {
		return (
				<div className="loading-container d-flex justify-content-center align-items-center vh-100">
				<Loading />
			</div>
		);
	}

	return (
		<div className="container-fluid pt-2">
			<div className="row d-flex justify-content-center flex-wrap flex-md-nowrap align-items-center pt-3 justify-content-md-start">
				<div className="col text-center text-md-start">
					<Typography variant="h1">{basicInfo?.data?.pool_name}</Typography>
				</div>
				{/* <div className="col d-flex align-items-center justify-content-center justify-content-md-end">
					<Button
						type="button"
						variant="secondary"
						fullWidth={false}
						onClick={() => navigate("/overview")}
					>
						Back to dashboard
					</Button>
				</div> */}
			</div>
			<CardSection title="Overview">
				<div className="col-12">
					<PoolSummary data={poolOverview?.data} lendingDetailData={basicInfo?.data} />
				</div>
				<div className="row">
					<div className="col-md-6 mb-3">
						<BasicInfo data={basicInfo?.data} />
					</div>
					<div className="col-md-6 mb-3">
						<BrandsInThePool data={basicInfo?.data?.brands} />
					</div>
				</div>
			</CardSection>
			<CardSection title="Repayment Schedule 2024">
				<BarChartComponent
					data={graphDataMapping}
					actualDataKey="actualRepaymentValue"
					projectedDataKey="projectedRepaymentValue"
				/>
			</CardSection>
			<CardSection title="Receivables Transparency">
				<ReceivablesTransparency data={loansData?.data} poolId={basicInfo?.data.id} />
			</CardSection>
			<CardSection title="Portfolio">
				<Tab tabsData={tabsData} tabType="pills" defaultTab={0} />
			</CardSection>
			<CardSection title="Accountability Features">
				<AccountabilityFeatures recentActivity={recentActivityData?.data} poolInfo={basicInfo?.data}/>
			</CardSection>
		</div>
	);
};

export default TradeFiPoolDetailPage;
