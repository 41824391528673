import React, { useEffect } from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom"; // Import BrowserRouter
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { store } from "./store"; // Import your store
import ModalProvider from "./providers/ModalProvider";
import { ThirdwebProvider, embeddedWallet } from "@thirdweb-dev/react";
import { NETWORKS } from "./web3/networks";
import * as amplitude from '@amplitude/analytics-browser';
import { initAmplitude } from './utils/amplitudeUtlis';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

const container = document.getElementById("root");
const root = createRoot(container);

const chainId = process.env.REACT_APP_DEFAULT_CHAIN_ID || "11155111";
const activeChainData = NETWORKS[chainId];

initAmplitude();

root.render(
    <React.StrictMode>
        <ThirdwebProvider
            activeChain={activeChainData.thirdWebData}
            clientId={process.env.REACT_APP_TEMPLATE_CLIENT_ID}
            supportedWallets={[embeddedWallet()]}
            sdkOptions={{
                gasSettings: {
                    speed: "fast",
                },
                gasless: {
                    engine: {
                        relayerUrl: activeChainData.relayerUrl,
                        domainName: activeChainData.forwarderDomainName,
                        domainVersion: activeChainData.forwarderVersion,
                        relayerForwarderAddress: activeChainData.forwarderAddress,
                    },
                },
            }}
            authConfig={{
                domain: process.env.REACT_APP_AUTH_DOMAIN,
            }}
        >
            <Provider store={store}>
                <BrowserRouter>
                    <ModalProvider>
                        <App />
                    </ModalProvider>
                </BrowserRouter>
            </Provider>
        </ThirdwebProvider>
    </React.StrictMode>
);

reportWebVitals();
