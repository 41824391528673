import './navbar.scss';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

export default function Navbar({ pools, showPoolsNav = false }) {
  return (
    <nav className="navbar navbar-expand-lg navbar-tradeFi fixed-top flex-column">
      {/* First Row */}
      <div className="container-fluid">
        <a className="navbar-brand" href="/">
          <img
            src="/images/hyperglade-logo-black.svg"
            className="ps-3 img-fluid"
            alt="logo"
          />
        </a>

        <button
          className="navbar-toggler py-2"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
          aria-controls="navbarCollapse"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="navbar-collapse collapse" id="navbarCollapse">
          <ul className="nav navbar-nav ms-auto d-flex align-items-lg-center">
            <li className="nav-item py-lg-0 py-2">
              <NavLink className="nav-link active" to="/">
                Dashboard
              </NavLink>
            </li>
            <li className="nav-item py-lg-0 py-2">
              <NavLink
                className="nav-link active"
                to="https://hyperglade.com/contact-hyperglade/"
              >
                Lend
              </NavLink>
            </li>
            <li className="nav-item py-lg-0 py-2">
              <NavLink
                className="nav-link active"
                to="https://hyperglade.com/contact-hyperglade/"
              >
                Borrow
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
