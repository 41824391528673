import { Outlet } from 'react-router-dom';
import Navbar from '../../components/Navbar/Navbar';
import { useGetAllLendingPoolQuery } from '../../services/tradeFiService';
import TabNav from '../../components/tradeFinance/TabNav/TabNav';

export default function TradeFiAppLayout() {
  const { data: poolsData, isLoading } = useGetAllLendingPoolQuery();

  return (
    <>
      <Navbar pools={poolsData?.data} showPoolsNav={true} />
      <div className="container-fluid">
        <TabNav pools={poolsData?.data} />
        <main style={{ paddingTop: '20px' }}>
          <Outlet />
        </main>
      </div>
    </>
  );
}
