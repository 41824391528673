// TradeFiSummary.jsx
import React, { useMemo } from 'react';
import Typography from '../../../components/Typography/Typography';
import TradeFiOverviewCard from '../../../components/tradeFinance/Card/tradeFiOverviewCard';
import { formatCurrency } from '../../../utils/formatUtils';
const TradeFiSummary = ({ overviewData, lendingDetailData }) => {
  const calculateTotalInvestment = useMemo(() => {
    try {
      if (!Array.isArray(lendingDetailData)) return 0;

      return lendingDetailData.reduce((total, pool) => {
        if (!pool?.totalInvestment) return total;

        const cleanValue = pool.totalInvestment.replace(/[$,]/g, '');
        const investment = parseFloat(cleanValue);

        return isNaN(investment) ? total : total + investment;
      }, 0);
    } catch (error) {
      console.error('Error calculating total investment:', error);
      return 0;
    }
  }, [lendingDetailData]);

  const generateColorVariations = (baseColor, count) => {
    // Convert hex to RGB first
    const r = parseInt(baseColor.slice(1, 3), 16);
    const g = parseInt(baseColor.slice(3, 5), 16);
    const b = parseInt(baseColor.slice(5, 7), 16);

    // Calculate Hue
    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);
    let h = 0;

    if (max === min) {
      h = 0;
    } else if (max === r) {
      h = 60 * ((g - b) / (max - min));
    } else if (max === g) {
      h = 60 * (2 + (b - r) / (max - min));
    } else {
      h = 60 * (4 + (r - g) / (max - min));
    }

    h = h < 0 ? h + 360 : h;

    const variations = [];
    for (let i = 0; i < count; i++) {
      const brightness = 20 + (i * 60) / count;
      variations.push(`hsl(${h}, 70%, ${brightness}%)`);
    }

    return variations;
  };

  const chartData = useMemo(() => {
    if (!Array.isArray(lendingDetailData)) return [];

    const baseColor = '#2196F3'; // Main blue color
    const colors = generateColorVariations(baseColor, lendingDetailData?.length+2); // (+2 <-- adjustment for the 2 coming soon pools)

    const poolsWithInvestment = lendingDetailData
      .map((pool) => ({
        name: pool.name,
        investment: parseFloat(pool.totalInvestment.replace(/[$,]/g, '')) || 0,
      }))
      .filter((pool) => pool.investment > 0);

    // Coming soon pools
    poolsWithInvestment.push(
      { name: 'Coming soon - Avalanche pool($90K)', investment: 90000 },
      { name: 'Coming soon - Backers pool($90k)', investment: 90000 },
    );

    const totalInvestment = poolsWithInvestment.reduce(
      (sum, pool) => sum + pool.investment,
      0
    );

    return poolsWithInvestment.map((pool, index) => ({
      name: pool.name,
      value: Math.round((pool.investment / totalInvestment) * 100),
      color: colors[index % colors.length],
    }));
  }, [lendingDetailData]);

  const calculateTotalCyclesDisbursed = useMemo(() => {
    try {
      if (!Array.isArray(lendingDetailData)) return 0;

      return lendingDetailData.reduce((total, pool) => {
        return total + (pool.cycle || 0);
      }, 0);
    } catch (error) {
      console.error('Error calculating total cycles:', error);
      return 0;
    }
  }, [lendingDetailData]);
  return (
    <div className="container-fluid mt-4 p-0">
      <Typography variant="h2" className="pt-2 text-center text-md-start">
        Protocol Overview
      </Typography>
      <div className="row">
        <div className="col-md-4 mb-3">
          <TradeFiOverviewCard
            title="Total Loans Disbursed"
            mainValue={formatCurrency(overviewData?.total_loan_disbursed_amount || 0, 'USD')}
            subValue={`${calculateTotalCyclesDisbursed} cycles disbursed`}
            icon={<i className="bi bi-currency-dollar"></i>}
          />
        </div>
        <div className="col-md-4 mb-3">
          <TradeFiOverviewCard
            title="Interest & Funds"
            mainValue={formatCurrency(overviewData?.total_interest_repaid || 0, 'USD')}
            subValue={`${formatCurrency(calculateTotalInvestment || 0, 'USD')} total funds invested`}
            icon={<i className="bi bi-currency-exchange"></i>}
          />
        </div>
        <div className="col-md-4 mb-3">
          <TradeFiOverviewCard
            title="Average Interest Rate"
            mainValue={
              overviewData?.average_interest_rate
                ? `${overviewData?.average_interest_rate}%`
                : '0%'
            }
            icon={<i className="bi bi-percent"></i>}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-4 mb-3">
          <TradeFiOverviewCard
            title="Total Loans Repaid"
            mainValue={formatCurrency(overviewData?.total_loans_repaid_amount || 0, 'USD')}
            icon={<i className="bi bi-arrow-repeat"></i>}
          />
        </div>
        <div className="col-md-4 mb-3">
          <TradeFiOverviewCard
            title="Investment Distribution"
            chartData={chartData}
            icon={<i className="bi bi-pie-chart"></i>}
          />
        </div>
      </div>
    </div>
  );
};

export default TradeFiSummary;
