import React from 'react';
import './card.scss';
import PieChartComponent from '../../Chart/PieChart';

export default function TradeFiOverviewCard({
  title,
  mainValue,
  subValue,
  icon,
  chartData,
  style,
}) {
  return (
    <div className="overview-card border" style={style}>
      <div className="overview-card-header">
        <h6 className="card-title">{title}</h6>
        {icon && <span className="card-icon">{icon}</span>}
      </div>
      <div className="card-content">
        {chartData ? (
          <div className="chart-container">
            <PieChartComponent data={chartData} />
          </div>
        ) : (
          <>
            <h2 className="main-value">{mainValue}</h2>
            {subValue && <p className="sub-value">{subValue}</p>}
          </>
        )}
      </div>
    </div>
  );
}
