/**
 * Formats a number as a currency string.
 *
 * @param {number} value - The numeric value to format.
 * @param {string} [currency=null] - The currency code (e.g., 'USD', 'EUR'). If null, the value is formatted as a plain number.
 * @param {boolean} [useSymbol=false] - Whether to use the currency symbol (e.g., '$') or the currency code (e.g., 'USD').
 * @returns {string} The formatted currency string.
 */
export const formatCurrency = (value, currency = null, useSymbol = false) => {
    if (currency) {
        const formattedValue = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: currency,
            currencyDisplay: 'symbol',
        }).format(value);

        if (useSymbol) {
            return formattedValue;
        } else {
            return formattedValue.replace(/[\p{Sc}]/u, '').trim() + ' ' + currency;
        }
    } else {
        return new Intl.NumberFormat('en-US').format(value);
    }
};
