import React, { useMemo } from 'react';
import { usePagination, useSortBy, useTable, useRowSelect } from 'react-table';
import './table.scss';

/**
 * Table component wrapped around `react-table`.
 * @param {{
 * data: any[],
 * columns: any[],
 * isSortable?: boolean,
 * isPaginated?: boolean,
 * isChecked?: boolean,
 * pageSize?: number,
 * pageIndex?: number,
 * }} props
 * @returns {React.FunctionComponent}
 */
export default function BasicTable({
  data = [], // Default value for data prop
  columns,
  isSortable = false,
  isPaginated = false,
  pageSize = 10,
  currentPage = 0,
  showCheckBox = false,
}) {
  const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = React.useRef();
      const resolvedRef = ref || defaultRef;

      React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate;
      }, [resolvedRef, indeterminate]);

      return (
        <div className="m-2 align-middle">
          <input
            type="checkbox"
            className="custom-control-input"
            ref={resolvedRef}
            {...rest}
          />
        </div>
      );
    }
  );

  const tableColumns = useMemo(
    () =>
      columns.map((col) => ({
        ...col,
        Header: col.header,
      })),
    [columns]
  );

  const checkboxColumn = {
    id: 'selection',
    Header: ({ getToggleAllRowsSelectedProps }) => (
      <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
    ),
    Cell: ({ row }) => (
      <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
    ),
  };

  if (showCheckBox) {
    tableColumns.unshift(checkboxColumn);
  }

  const tableInstance = useTable(
    {
      columns: tableColumns,
      data: data,
      initialState: {
        pageSize: pageSize,
        pageIndex: currentPage,
      },
    },
    useSortBy,
    usePagination,
    useRowSelect
  );

  const getHeaderProps = (tableInstance, column) => {
    if (isSortable) {
      return column.getHeaderProps(column.getSortByToggleProps());
    }

    return column.getHeaderProps();
  };

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  return (
    <div>
      <div className="table-responsive">
        <table
          className="table table-borderless table-sm shadow-sm w-100 w-sm-auto"
          {...getTableProps()}
        >
          <thead className="m-1 table-header">
            {headerGroups.map((headerGroup) => {
              const { key, ...headerGroupProps } =
                headerGroup.getHeaderGroupProps();
              return (
                <tr key={key} {...headerGroupProps}>
                  {headerGroup.headers.map((column) => {
                    const { key, ...columnProps } = getHeaderProps(
                      tableInstance,
                      column
                    );
                    return (
                      <th
                        key={key}
                        className="align-middle px-3"
                        {...columnProps}
                      >
                        {column.render('Header')}
                        {isSortable && (
                          <span>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? ' ˅'
                                : ' ˄'
                              : ''}
                          </span>
                        )}
                      </th>
                    );
                  })}
                </tr>
              );
            })}
          </thead>
          <tbody {...getTableBodyProps()}>
            {(isPaginated ? tableInstance.page : rows).map((row) => {
              prepareRow(row);
              const { key, ...rowProps } = row.getRowProps();
              return (
                <tr key={key} {...rowProps} className="table-row-border">
                  {row.cells.map((cell) => {
                    const { key, ...cellProps } = cell.getCellProps();
                    return (
                      <td
                        key={key}
                        className="align-middle py-2 px-3"
                        {...cellProps}
                        data-label={cell.column.Header}
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {isPaginated && (
        <div className="d-flex justify-content-md-end justify-content-center mb-2 mb-md-0 mt-5 table-pagination">
          <nav>
            <ul className="pagination">
              <li
                className={`m-1 page-item ${
                  !tableInstance.canPreviousPage ? 'disabled' : ''
                }`}
              >
                <button
                  className="page-navigator page-link previous-page"
                  onClick={(e) => {
                    e.preventDefault();
                    tableInstance.previousPage();
                  }}
                >
                  <span className="m-1">{`<`}</span>
                  Previous
                </button>
              </li>
              {tableInstance.pageCount > 0 && (
                <>
                  {[...Array(tableInstance.pageCount)].map((_, p) => (
                    <li
                      key={p}
                      className={`m-1 page-item ${
                        p === tableInstance.state.pageIndex ? 'active' : ''
                      }`}
                    >
                      <button
                        className="page-link page-number"
                        onClick={(e) => {
                          e.preventDefault();
                          tableInstance.gotoPage(p);
                        }}
                      >
                        {p + 1}
                      </button>
                    </li>
                  ))}
                </>
              )}
              <li
                className={`m-1 page-item ${
                  !tableInstance.canNextPage ? 'disabled' : ''
                }`}
              >
                <button
                  className="page-navigator page-link next-page"
                  onClick={(e) => {
                    e.preventDefault();
                    tableInstance.nextPage();
                  }}
                >
                  Next<span className="m-1">{`>`}</span>
                </button>
              </li>
            </ul>
          </nav>
        </div>
      )}
    </div>
  );
}
