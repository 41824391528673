import React from 'react';
import { convertRiskToText } from "../utils/projectUtils";
const RiskRatingBadge = ({ rating }) => {
  const risk = convertRiskToText(rating); 
  const getColor = (risk) => {
    switch (risk.toLowerCase()) {
      case 'low':
        return '#4CAF50'; // green
      case 'medium':
        return '#FFC107'; // yellow
      case 'high':
        return '#F44336'; // red
      default:
        return '#757575'; // grey
    }
  };

  return (
    <span
      style={{
        backgroundColor: getColor(risk),
        color: 'white',
        padding: '4px 8px',
        borderRadius: '12px',
        fontSize: '12px',
        fontWeight: '500',
      }}
    >
      {risk}
    </span>
  );
};

export default RiskRatingBadge;
