import Typography from "../../../components/Typography/Typography";
import Button from "../../../components/Button/Button";
import { BasicTable } from "../../../components/tables";
import "./tradeFiOverview.scss";
import { formatRisk } from "../../../utils/projectUtils";

const PoolList = ({
	tableData,
	handleOnViewPoolLinkClick,
	handleCreateNewPool,
}) => {
	return (
		<div className="container-fluid my-4 p-0">
			<div className="card">
				<div className="card-body">
					<div className="col-12 px-2">
						<Typography variant="h2" className="py-3 text-center text-md-start">
						Pool Details
						</Typography>
						{tableData?.length > 0 ? (
						<BasicTable
							data={tableData}
							columns={[
							{
								header: 'Name',
								accessor: 'name',
								Cell: ({ value }) => {
								return <div>{value || 'N/A'}</div>;
								},
							},
							{
								header: 'APY',
								accessor: 'apy',
								Cell: ({ value }) => {
								return (
									<div className="apy-component">{value || 'N/A'}</div>
								);
								},
							},
							{
								header: 'Active Loans(USD)',
								accessor: 'totalActiveLoans',
								Cell: ({ value }) => {
									return <div>{value || 'N/A'}</div>;
								},
							},
							{
								header: 'Total Loan Disbursed(USD)',
								accessor: 'totalLoanDisbursed',
								Cell: ({ value }) => {
									return <div>{value || 'N/A'}</div>;
								},
							},
							{
								header:"Strategy",
								accessor:"description",
								Cell:({value})=>{
									return <div>{value || 'N/A'}</div>
								}
							},
							{
								header: "Actions",
								accessor: "actions",
								Cell: ({ row }) => {
									return (
										<Button
											onClick={() => handleOnViewPoolLinkClick(row.original.id)}
											className="view-pool-button"
										>
											View
										</Button>
									);
								},
							}
							]}
							isSortable={false}
							isPaginated={tableData?.length > 5}
							pageSize={5}
							currentPage={0}
							showCheckbox={false}
						/>
						) : (
						<div className="d-flex flex-column align-items-center text-center pt-3">
							<img src="/images/placeholderImage.svg" alt="Placeholder" />
							<Typography variant="h3" className="pt-3">
								Start Your First Pool
							</Typography>
							<Typography variant="body1" className="pt-2">
								You haven't started any pools yet. Click the button below to
								start your first trade finance pool.
							</Typography>
							<Button
							className="start-pool-button"
							fullWidth={false}
							onClick={handleCreateNewPool}
							>
								Start Pool
							</Button>
						</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default PoolList;