import React, { useState } from "react";
import "./tab.scss";
import { useNavigate, useParams } from "react-router-dom";

function Tab({ tabsData, tabType, defaultTab }) {
    const { tabName } = useParams();
    const tabIndex = tabsData.findIndex((tab) => tab.path === tabName);
    const [activeTab, setActiveTab] = useState(
        tabIndex > -1 ? tabIndex : defaultTab
    );
    const [activePillTab, setActivePillTab] = useState(0);
    const navigate = useNavigate();

    const handleTabChange = (tabIndex, tabType,event) => {
        event.preventDefault(); 
        if (tabType === "pills") {
            setActivePillTab(tabIndex);
        } else {
            setActiveTab(tabIndex);
            // navigate(tabsData[tabIndex].path);
        }
    };

    return (
        <div className="tabbable-panel">
            <ul
                className={`nav nav-${tabType} mb-3 justify-content-center justify-content-sm-start`}
                id="ex1"
                role="tablist"
            >
                {tabsData.map((tab, index) => (
                    <li
                        key={index}
                        className={`nav-item ${
                            (tabType === "pills"
                                ? activePillTab
                                : activeTab) === index
                                ? "active"
                                : ""
                        }`}
                        role="presentation"
                    >
                        <a
                            className={`nav-link ${
                                (tabType === "pills"
                                    ? activePillTab
                                    : activeTab) === index
                                    ? "active"
                                    : ""
                            } text-center text-sm-start`}
                            id={`ex2-tab-${index}`}
                            data-bs-toggle="tab"
                            href={`#ex2-tabs-${index}`}
                            role="tab"
                            aria-controls={`ex2-tabs-${index}`}
                            aria-selected={
                                (tabType === "pills"
                                    ? activePillTab
                                    : activeTab) === index
                            }
                            onClick={(event) => handleTabChange(index, tabType, event)}
                        >
                            {tab.name}
                        </a>
                    </li>
                ))}
            </ul>
            <div className="tab-content " id="ex2-content">
                {tabsData.map((tab, index) => (
                    <div
                        key={index}
                        className={`tab-pane fade ${
                            (tabType === "pills"
                                ? activePillTab
                                : activeTab) === index
                                ? "show active"
                                : ""
                        }`}
                        id={`ex2-tabs-${index}`}
                        role="tabpanel"
                        aria-labelledby={`ex2-tab-${index}`}
                    >
                        {(tabType === "pills" ? activePillTab : activeTab) ===
                            index && tab.component}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Tab;
