import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import "./tabnav.scss"

const TabNav = ({ pools }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState('overview');

  useEffect(() => {
    const pathSegments = location.pathname.split('/');
    const currentPool = pathSegments[2];
    if (location.pathname === '/') {
      setActiveTab('dashboard');
    } else {
      setActiveTab(currentPool || 'overview');
    }
  }, [location]);

  const handleTabClick = (poolId) => {
    setActiveTab(poolId);
    if (poolId === 'dashboard') {
      navigate('/');
    } else {
      navigate(`/overview/${poolId}`);
    }
  };

  return (
    <div className="underline-tabs-container">
      <div className="underline-tabs">
        <button
          className={`tab-button ${activeTab === 'dashboard' ? 'active' : ''}`}
          onClick={() => handleTabClick('dashboard')}
        >
          Dashboard
        </button>
        {pools?.map((pool) => (
          <button
            key={pool.id}
            className={`tab-button ${activeTab === pool.id ? 'active' : ''}`}
            onClick={() => handleTabClick(pool.id)}
          >
            {pool.pool_name}
          </button>
        ))}
      </div>
    </div>
  );
};

export default TabNav;