import React from "react";
import "./tradeFiPoolDetail.scss";
import { formatRisk } from "../../../utils/projectUtils";
import Typography from "../../../components/Typography/Typography";
const InfoRow = ({ title, value }) => (
	<div className="row">
		<div className="col-md-4">
			<Typography variant="body1" className="fw-bold p-0">{title}</Typography>
		</div>
		<div className="col-md-8">
			<Typography variant="body1" className="p-0 fw-bold text-muted">{value || "N/A"}</Typography>
		</div>
	</div>
);

const BasicInfo = ({
	data
}) => {
	return (
		<div className="card mt-2 p-3 h-100">
			<div className="card-body text-center text-md-start">
				<Typography variant="h2" className="m-0 pt-3 text-center text-md-start">
					Pool Thesis
				</Typography>
				<div className="card-text">
					<Typography variant="body1" className="m-0 pt-3">
						{data.meta_data?.pool_thesis || "N/A"}
					</Typography>
				</div>
				<div className="additional-info mt-4">
					<InfoRow title="Expected Overall Returns" value={data.meta_data?.expected_overall_returns || "N/A"} />
					<InfoRow title="Risk Level" value={formatRisk(data.risk_rating) || "N/A"} />
					{/* <InfoRow title="Total Pool Value" value={new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(data.total_investment)} />
					<InfoRow title="Active Loan Value" value={new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(data.totalLoan)} />
					<InfoRow title="APY" value={`${data.apy}%`} />
					<InfoRow title="Country Rating" value={data.country_rating} />
					<InfoRow title="Liquidity" value={data.liquidity} /> */}
				</div>
			</div>
		</div>
	);
};

export default BasicInfo;
