import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth, publicBaseQuery } from './baseQueries';

// Dummy data to be returned by the query
const dummyData = {
    data: {
        active_loans: "$0",
        total_loss_rate: "0%",
        total_loans_repaid: "$0",
        interest_repaid: "$0",
        pools: [
            {
                pool_id: "1",
                pool_name: "Pool 1",
                pool_apy: 10.34,
                pool_tvl: 12312232,
                pool_risk_rating: "NaN",
            },
            {
                pool_id: "2",
                pool_name: "Pool 2",
                pool_apy: 9.56,
                pool_tvl: 9345123,
                pool_risk_rating: "NaN",
            },
            {
                pool_id: "3",
                pool_name: "Pool 3",
                pool_apy: 8.92,
                pool_tvl: 14213123,
                pool_risk_rating: "NaN",
            },
            {
                pool_id: "4",
                pool_name: "Pool 4",
                pool_apy: 11.23,
                pool_tvl: 7123123,
                pool_risk_rating: "NaN",
            },
            {
                pool_id: "5",
                pool_name: "Pool 5",
                pool_apy: 9.87,
                pool_tvl: 11234567,
                pool_risk_rating: "NaN",
            },
        ],
    },
};

const empty = {
    data: {
        active_loans: "$0",
        total_loss_rate: "0%",
        total_loans_repaid: "$0",
        interest_repaid: "$0",
        pools: [],
    },
}

// Simplified function to return dummy data
export function useGetTradeFiOverviewQuery() {
    return {
        data: dummyData,
        isLoading: false,
        refetch: () => { },
    };
}

export const tradeFiAPI = createApi({
    reducerPath: 'tradeFiAPI',
    baseQuery: publicBaseQuery,
    tagTypes: ['TradeFinance'],
    endpoints: (builder) => ({
        getAllLendingPool: builder.query({
            query: () => '/api/templates/tradefi/lending_pool_group/',
            providesTags: ['TradeFinance'],
        }),
        getGraphData: builder.query({
            query: ({ id, year }) => {
                let baseUrl = `/api/templates/tradefi/lending_pool_group/${id}/repayments/`;
                if (year) {
                    baseUrl += `?year=${year}`;
                }
                return baseUrl;
            },
            providesTags: ['TradeFinance'],
        }),
        getLendingPoolById: builder.query({
            query: (poolid) => `/api/templates/tradefi/lending_pool_group/${poolid}/`,
            providesTags: ['TradeFinance'],
        }),
        getLoansInLendingPool: builder.query({
            query: (id) => `/api/templates/tradefi/lending_pool_group/${id}/loans/`,
            providesTags: ['TradeFinance'],
        }),
        getAllLoanInfo: builder.query({
            query: () => '/api/templates/tradefi/lending_pool/all-loan-info/',
            providesTags: ['TradeFinance'],
        }),
        getOverviewOfAPool: builder.query({
            query: (id) => `/api/templates/tradefi/lending_pool_group/${id}/overview/`,
            providesTags: ['TradeFinance'],
        }),
        getAllBorrowersInfo: builder.query({
            query: (id) => `/api/templates/tradefi/lending_pool_group/${id}/borrowers/`,
            providesTags: ['TradeFinance'],
        }),
        getAllRecentActivity: builder.query({
            query: (id) => `/api/templates/tradefi/lending_pool_group/${id}/tx-history/`,
            providesTags: ['TradeFinance'],
        }),
    }),
});

export const { useGetAllLendingPoolQuery, useGetGraphDataQuery, useGetLendingPoolByIdQuery, useGetLoansInLendingPoolQuery, useGetAllLoanInfoQuery, useGetOverviewOfAPoolQuery, useGetAllBorrowersInfoQuery, useGetAllRecentActivityQuery } = tradeFiAPI;